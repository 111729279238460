<template>
	<div class="mainframe">
		<div class="off-room">
			<img v-if="ligthSwitch.isOn" class="lightswitch" src="../assets/img/light-switch-on.png" alt="A lightswith on" />
			<img
				v-if="!ligthSwitch.isOn"
				class="lightswitch"
				src="../assets/img/light-switch-off.png"
				alt="A lightswith off"
				v-on:click="switchLight(true)"
			/>
		</div>

		<div class="curtain" :class="{ 'curtain-fall': ligthSwitch.isOn }"></div>

		<section v-if="itsBirthday">
			<div class="party-room" :class="{ 'light-on': ligthSwitch.isOn }" v-show="true">
				<div class="ceiling">
					<img src="../assets/img/flags.png" alt="Triangular flags" class="flags float-left" />
					<img src="../assets/img/disco-ball.png" alt="A disco ball" class="discoball" />
					<img src="../assets/img/flags.png" alt="Triangular flags " class="flags mirrored float-left" />
				</div>

				<div class="party-zone">
					<div class="cake-zone">
						<img src="../assets/img/cake-on-table.png" alt="The cake is a lie" class="cake float-left" />
					</div>

					<img src="../assets/img/birthday_pg.png" alt="A mysterious being with ballons" class="guest" v-on:click="clickedGiorgio()" />
					<img
						v-if="!audioPaused"
						src="../assets/img/stereo-on.png"
						alt="A stereo turned on"
						class="stereo"
						@click="toggleMusicPlay()"
					/>
					<img
						v-if="audioPaused"
						src="../assets/img/stereo-off.png"
						alt="A stereo turned off"
						class="stereo"
						@click="toggleMusicPlay()"
					/>

					<audio id="speakingAudio" src="../assets/audio/similUndertaleTalk.mp3" loop></audio>
				</div>

				<img
					v-for="balloon in balloons"
					:id="balloon.id"
					:key="balloon.id"
					:src="balloon.src"
					:style="balloon.style"
					class="balloon"
					:class="{ 'fly-balloon': ligthSwitch.isOn }"
				/>
			</div>
		</section>

		<section v-if="!itsBirthday">
			<div class="empty-room">
				<div>
					<img src="../assets/img/abandoned-flags.png" alt="Triangular flags" class="flags h-margin-2_0" />
					<img src="../assets/img/abandoned-flags.png" alt="Triangular flags" class="flags mirrored h-margin-2_0" />
				</div>
				<div class="empty-zone">
					<img src="../assets/img/card-on-table.png" alt="A greeting card on a table" @click="toggleGreetingCard()" class="table" />
					<img
						v-if="!audioPaused"
						src="../assets/img/stereo-on.png"
						alt="A stereo turned on"
						class="stereo"
						@click="toggleMusicPlay()"
					/>
					<img
						v-if="audioPaused"
						src="../assets/img/stereo-off.png"
						alt="A stereo turned off"
						class="stereo"
						@click="toggleMusicPlay()"
					/>
				</div>
			</div>

			<div class="greeting-card v-margin-2_0"></div>
		</section>

		<section v-if="!itsBirthday && showGreetingCard">
			<img src="../assets/img/HBVale_1.jpg" class="openedGreetingCard" :class="{ openCard: showGreetingCard }" />
		</section>

		<div class="dialogBalloonContainer">
			<p class="dialogBalloon" v-bind:class="{ dialogBallonAnimation: isSpeaking }">
				{{ spokenText }}
			</p>
		</div>

		<audio id="backgroundAudio" loop></audio>

		<!-- <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
	</div>
</template>

<script>
export default {
	name: "MainPage",
	props: {},
	data() {
		return {
			spokenText: "",
			isSpeaking: false,
			backgroundAudio: {},
			speakingAudio: {},
			audioPaused: true,
			ligthSwitch: {
				isOn: false,
			},
			lightOn: false,
			phrases: ["Ciao Vale", "Ti faccio gli auguri di felice compleanno!"],
			optionalPhrases: [
				{ text: "La torta è una bugia, peccato", played: 0, increase: 2 },
				{ text: "Cosa ne pensi di questi palloncini?", played: 1, increase: 2 },
				{ text: "La musica si può fermare usando lo stereo", played: 0, increase: 2 },
				{ text: "Come sta andando la tua giornata?", played: 1, increase: 2 },
				{ text: "La palla disco è qui perché dalla faccia ci crede molto", played: 1, increase: 2 },
				{ text: "Sarò qui tutto il giorno!", played: 1, increase: 2 },
				{ text: "Non c'é molto altro qui intorno", played: 1, increase: 2 },
				{ text: "Esisto solo a fini personali, non commerciali!!!", played: 1, increase: 3 },
				{ text: "Spero ti piaccia questo pseudo regalo", played: 1, increase: 3 },
				{ text: "Dovrei riparare l'interruttore...", played: 3, increase: 3 },
				/* { text: "", played: 0 } */
			],
			balloons: [
				{ id: "ballon1", src: require("../assets/img/balloon_red.png") },
				{ id: "ballon2", src: require("../assets/img/balloon_green.png") },
				{ id: "ballon3", src: require("../assets/img/balloons_hearts.png") },
				{ id: "ballon4", src: require("../assets/img/balloons_hearts_2.png") },
				{ id: "ballon5", src: require("../assets/img/balloons_1.png") },
				{ id: "ballon6", src: require("../assets/img/balloons_2.png") },
				{ id: "ballon7", src: require("../assets/img/balloons_3.png") },
			],
			showGreetingCard: false,
		};
	},

	computed: {
		itsBirthday: function () {
			let today = new Date();
			return today > new Date("2022-11-8") && today < new Date("2022-11-9");
		},
	},

	mounted: function () {
		this.init();
	},

	methods: {
		init: function () {
			let self = this;

			self.backgroundAudio = document.querySelector("#backgroundAudio");
			self.backgroundAudio.volume = 0.25;

			if (this.itsBirthday) {
				self.backgroundAudio.src = require("../assets/audio/partyMusic.mp3");
			} else {
				self.backgroundAudio.src = require("../assets/audio/anotherDayMusic.mp3");
			}

			self.audioPaused = self.backgroundAudio.paused;
		},

		startParty: async function () {
			let self = this;

			self.balloons.forEach(function (balloon) {
				self.randomizeBalloon(balloon);
			});

			await self.sleeper(500);

			self.speakingAudio = document.querySelector("#speakingAudio");
			self.speakingAudio.volume = 0.25;
			self.backgroundAudio.play();
			self.audioPaused = self.backgroundAudio.paused;

			self
				.speak(self.phrases[0])
				.then(async function () {
					await self.sleeper(1500);
					self.speak(self.phrases[1]).then(function () {
						self.beQuiet();
					});
				})
				.catch(function () {});
		},

		toggleMusicPlay: function () {
			if (this.backgroundAudio.paused) {
				this.backgroundAudio.play();
			} else {
				this.backgroundAudio.pause();
			}
			this.audioPaused = this.backgroundAudio.paused;
		},

		clickedGiorgio: async function () {
			let self = this;

			if (self.isSpeaking) return;

			self.optionalPhrases.sort(function (el1, el2) {
				return el1.played - el2.played;
			});

			let phrase = self.optionalPhrases[0];
			phrase.played += phrase.increase;

			self
				.speak(phrase.text)
				.then(async function () {
					await self.sleeper(100);
					self.beQuiet();
				})
				.catch(function () {});
		},

		speak: async function (text) {
			let self = this;

			self.isSpeaking = true;
			self.spokenText = "";

			self.speakingAudio.play();

			let words = text.split("");
			for (const word of words) {
				self.spokenText += word;
				await self.sleeper(100);
			}
		},

		beQuiet: async function () {
			let self = this;
			self.isSpeaking = false;
			await self.sleeper(100);
			self.speakingAudio.pause();
		},

		switchLight: function (switchState) {
			this.ligthSwitch.isOn = switchState;

			if (this.itsBirthday) {
				this.startParty();
			} else {
				this.lightEmptyRoom();
			}
		},

		toggleGreetingCard: function () {
			this.showGreetingCard = !this.showGreetingCard;
		},

		lightEmptyRoom: function () {},

		randomizeBalloon: function (balloon) {
			var balloon_html = document.querySelector(`#${balloon.id}`);

			let marginLeft = Math.random() * 75 + Math.random() * 50 + Math.random() * 25;
			marginLeft = Math.random() > 0.5 ? marginLeft * 0.9 : marginLeft * -1.5;
			marginLeft = marginLeft > 150 ? marginLeft - 150 : marginLeft;

			let flyDuration = Math.random() * 10 + 4;
			let finalPositionX = Math.random() > 0.5 ? Math.random() * -60 : Math.random() * 40;
			let finalPositionY = (200 + Math.round((Math.random() * 100) / 2)) * -1;

			balloon_html.style.setProperty("margin-left", marginLeft + "px");
			balloon_html.style.setProperty("--animation-time-flying", flyDuration + "s");
			balloon_html.style.setProperty("--animation-posX-flying", finalPositionX + "%");
			balloon_html.style.setProperty("--animation-posY-flying", finalPositionY + "%");
		},

		sleeper: function (millis) {
			return new Promise(function (resolve) {
				setTimeout(() => resolve(), millis);
			});
		},
	},
};
</script>

<style scoped>
.float-left {
	float: left;
}

.mainframe {
	position: absolute;
	/* background-color: red; */
	max-width: 80%;
	min-width: 375px;
	height: 550px;
	margin: 0 50%;
	transform: translate(-50%, 0);
}

.off-room {
	margin-top: 20px;
}

.party-room {
	text-align: center;
	/* background: blue; */
	height: 300px;
	margin-top: 2rem;
	background-color: white;
}

.ceiling {
	text-align: center;
}

.mirrored {
	transform: scaleX(-1);
}

.discoball {
	margin: 0;
	width: 4rem;
	float: left;
	margin: 0 3.5rem;
}

.stereo {
	width: 4rem;
}

.flags {
	margin: 0;
	width: 6rem;
}

.cake {
	width: 4.5rem;
	margin-top: 3rem;
}

.table {
	width: 5rem;
	margin: 3rem 8rem 0rem 1rem;
}

.balloon {
	opacity: 0;
	margin: 0;
	width: 4rem;
	position: absolute;
}

.guest {
	width: 8rem;
	margin: 1rem 1rem 2rem 0rem;
	text-align: center;
}

.card {
	width: 3rem;
	margin-top: 6rem;
}

.lightswitch {
	width: 4rem;
}

.light-on {
	animation: lighton 1s;
}

.dialogBalloon {
	display: block;
	font-weight: bold;
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	margin: 0 auto; /* Gives that scrolling effect as the typing happens */
	letter-spacing: 0.2em; /* Adjust as needed */
}

.dialogBalloonAnimation {
	animation: typing 3s steps(40, end);
}

.dialogBalloonContainer {
	display: block;
	block-size: fit-content;
	width: 90%;
	margin: 25px 50%;
	transform: translate(-50%, 0);
}

.fly-balloon {
	animation: flying var(--animation-time-flying);
	animation-iteration-count: 5;
	animation-delay: 1s;
}

.curtain {
	position: fixed;
	background-color: black;
	height: 550px;
	width: 375px;
	z-index: 10;
}

.curtain-fall {
	animation: fall 1s forwards;
}

@keyframes fall {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		z-index: -10;
		visibility: none;
	}
}

@keyframes lighton {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes flying {
	from {
	}
	15% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	to {
		margin-top: var(--animation-posY-flying);
		margin-left: var(--animation-posX-flying);
		opacity: 0;
	}
}

@keyframes typing {
	from {
		width: 75%;
	}
	to {
		width: 100%;
	}
}

@keyframes blink-caret {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: orange;
	}
}

.openedGreetingCard {
	width: 85vw;
}

.openCard {
	animation: opencard 2s forwards;
}

/* The typewriter cursor effect */
@keyframes opencard {
	from {
		transform: rotate3d(30, 20, 40, 120deg);
	}
	40%{
		transform: rotate3d(30, 20, 40, -90deg);
	}
	to {
		transform: rotate3d(0, 0, 0, 1deg);
	}
}

.h-margin-2_0 {
	margin: 0rem 2rem;
}

.v-margin-2_0 {
	margin: 2rem 0rem;
}
</style>
