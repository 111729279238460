<template>
  <div id="app">
    <MainPage />
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  font-size: 16px;
  background-color: rgba(160,180,175,0.2);
  height: 100vh;
}

:root {
  --animation-time-flying: 10s;
  --animation-posX-flying: -100%;
  --animation-posY-flying: -200%;
}

</style>
